<template>
    <div class="w-full h-full px-3 py-4">

        <All :model="infomodel" :immediate="true" :config="config" v-slot="{data:infodata,loading:loadinginfo}">

            <div v-if="!loadinginfo" class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6">

                <div class="h-16 flex flex-row">

                    <div class="h-full w-10/12 flex flex-row">

                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            
                            <vue-initials-img :name="comercial.Name || comercial.ZonesName" class="h-12 w-12 rounded-full"/>                       
                            
                        </div>

                        <div class="h-full w-9/12 flex flex-col justify-center items-start truncate">
                            <span class="text-lg text-dfont font-semibold truncate">{{comercial.Name || comercial.ZonesName}}</span>

                            <div v-if="user.rol.Name == 'Comercial'" class="h-auto">

                                <span class="text-xs text-gray">{{$t('zone')}} {{zonasel}} {{saletypename}}</span>
                                <div class="h-auto" v-if="zonefiltered">
                                </div>
                            
                                <div class="h-auto" v-else>
                                    <span class="text-xs text-gray">{{$t('allZones')}}</span>
                                </div>

                            </div>

                            <div v-else class="h-auto">

                                 <div class="h-auto" v-if="zonefiltered">
                                    <span class="text-xs text-gray">{{$t('zone')}} {{zonasel}}</span>
                                </div>
                            
                                <div class="h-auto" v-else>
                                    <span class="text-xs text-gray">{{$t('allZones')}}</span>
                                </div>

                            </div>
                        
                        </div>

                    </div>

                    <div class="h-full w-2/12 flex flex-col justify-start items-end">
                        <i class="mdi mdi-dots-vertical text-gray text-xl" @click="gotocomercialfilters()"></i>
                    </div>

                </div>

                <div v-if="rolpermisions" class="h-10 mt-6 mx-2 rounded-lg p-1 bg-lightgray flex flex-row overflow-hidden">

                    <div class="h-full w-full rounded-lg flex flex-row justify-center items-center bg-lightgray" @click="toTops()">
                        <i class="mdi mdi-filter-variant text-purple text-xl font-semibold"></i>
                    </div>

                </div>

                <readmessages v-if="permisions && comercial.Id" endpoint="Comercialmessage" :querymessage="querymessage"></readmessages>

                <All :model="modelComercial" :immediate="true" :config="config" :query="query" v-slot="{data,loading}">
                    
                    <div v-if="!loading" class="h-auto">

                        <div v-if="!preciomedio && !margen" class="h-auto w-full flex flex-row mt-2">
                            <billing v-if="!metroscuadrados" :data="data.BubbleSale" :model="model" :flag="false"  :config="configFacturation" @openFacturation="openFacturation" :lastSale="infodata.LastSaleDate"></billing>
                            <meters v-if="!facturation" :data="data.BubbleMeter" :model="model" :config="configMetros" :flag="false" @openMeters="openMeters" :lastSale="infodata.LastSaleDate"></meters>
                        </div>

                        <div v-if="!facturation && !metroscuadrados" class="h-auto w-full flex flex-row mt-2">
                            <halfprice v-if="!margen" :data="data.BubbleMediumPrice" :model="model" :config="configPreciomedio" @fromhalfprice="openPreciomedio" :isOpen="preciomedio" :lastSale="infodata.LastSaleDate"></halfprice>
                            <margin v-if="!preciomedio" :data="data.BubbleMargin" :model="model" :config="configMargen" @openMargen="openMargen" :isOpen="margen" :lastSale="infodata.LastSaleDate"></margin>
                        </div>

                        <div class="h-24 mt-4 flex flex-col">
                            <div class="h-1/2 flex flex-row">
                                <div class="h-full w-1/2 p-1" @click="toKpis()">
                                    <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-start items-center px-4">
                                        <i class="mdi mdi-chart-line-variant text-blue mr-2"></i>
                                        <span class="text-xs text-dfont font-semibold">KPIs</span>
                                    </div>
                                </div>
                                <div class="h-full w-1/2 p-1">
                                    <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-start items-center px-4" @click="toVisits()">
                                        <i class="mdi mdi-map-marker text-pink mr-2"></i>
                                        <span class="text-xs text-dfont font-semibold">{{$t('visits')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="h-1/2 flex flex-row">
                                <div class="h-full w-1/2 p-1">
                                    <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-start items-center px-4" @click="toClients()">
                                        <i class="mdi mdi-store text-orange mr-2"></i>
                                        <span class="text-xs text-dfont font-semibold">{{$t('customers')}}</span>
                                    </div>
                                </div>                    
                                <div class="h-full w-1/2 p-1">
                                    <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-start items-center px-4" @click="toRepresentants()">
                                        <i class="mdi mdi-account-multiple-outline text-blue mr-2"></i>
                                        <span class="text-xs text-dfont font-semibold">{{getReduceName($t('representatives'))}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div v-else class="h-20 relative">
                        <loader :loading="loading"></loader>
                    </div>

                </All>

            </div>

        </All>

    </div>
</template>

<script>
    import { state } from '@/store';
    import solidgauge from '../../components/solidgauge.vue';
    import { All } from '@/api/components';
    import loader from '../../components/loader.vue';
    import billing from '../../components/billing.vue';
    import meters from '../../components/meters.vue';
    import halfprice from '../../components/halfprice.vue';
    import margin from '../../components/margin.vue';
    import readmessages from '../../components/readmessages.vue';

    export default {
        name: 'representante',
        components:{
            solidgauge,
            All,
            loader,
            billing,
            meters,
            halfprice,
            margin,
            readmessages
        },
        data(){
            return{
                comercial:{
                    Photo:false
                },
                company: null,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
                yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
                month: this.$moment().format("MMM 'YY"),
                montprevyear: this.$moment().subtract(1, 'years').format("MMM 'YY"),
                monthprev: this.$moment().subtract(1, 'months').format("MMM 'YY"),
                preciomedio: false,
                margen: false,
                facturation: false,
                metroscuadrados: false

            }
        },
        mounted(){
            this.comercial = state.user
        },
        computed: {
            user() {
                return state.user;
            },
            config(){

                if(this.modelComercial == 'ComercialZone'){

                    if(state.filterZoneComercial == null){
                    
                        return{
                            data:{
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Zone: state.idZone,
                                IdUser: state.userId,
                            }
                        }

                    } else {

                        return{
                            data:{
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Zone: state.filterZoneComercial,
                                IdUser: state.userId,
                            }
                        }

                    }

                } else if(this.modelComercial == 'ComercialUser'){

                    return{
                        data:{
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            IdUser: state.userId,
                            Zone: state.idZone
                        }
                    }

                }
            },
            modelComercial(){

                if(state.comercialSelected.Id && state.filterZoneComercial == null){

                    return 'ComercialUser'

                } else {

                    return 'ComercialZone'

                }

            },
            configFacturation(){
                return {
                    data: {
                        Dimension: 0,
                        Company: state.idCompany,
                        IdUser: state.userId,
                        Zone: state.idZone,
                        period: state.period,
                        SaleType: state.saleTypeSelected,
                    },
                };
            },
            configMetros() {
                return {
                    data: {
                        Dimension: 1,
                        Company: state.idCompany,
                        IdUser: state.userId,
                        SaleType: state.saleTypeSelected,
                        Zone: state.idZone,
                        period: state.period
                    },
                };
            },
            configPreciomedio() {
                return {
                    data: {
                        Dimension: 2,
                        Company: state.idCompany,
                        IdUser: state.userId,
                        SaleType: state.saleTypeSelected,
                        Zone: state.idZone,
                        period: state.period
                    },
                };
            },
            configMargen() {
                return {
                    data: {
                        Dimension: 3,
                        Company: state.idCompany,
                        IdUser: state.userId,
                        SaleType: state.saleTypeSelected,
                        Zone: state.idZone,
                        period: state.period
                    },
                };
            },
            query(){
                return{
                    period: state.period,
                    IndustrialGroup: state.industrialGroup
                }
            },
            zone(){
                return state.zoneSelected;
            },
            model(){
                if(state.comercialSelected.Id){
                    return 'ComercialbubbledetailUser'
                } else {
                    return 'ComercialbubbledetailZone'
                }
            },
            zonefiltered(){
                return state.filterZoneComercial
            },
            infomodel(){

                if(state.comercialSelected.Id != null){
                    return 'ComercialInfoUser'
                } else {
                    return 'ComercialInfoZone'
                }

            },
            zonasel(){
                return state.filterZoneComercialName
            },
            saletype(){
                return state.saleTypeSelected
            },
            saletypename(){
                return state.zoneSelected.substr(0,3)
            },
            querymessage(){
                if(state.user.rol.Name != 'Comercial'){
                    return{
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        IdUser: state.comercialSelected.Id
                    }
                } else {

                    return{
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        IdUser: state.user.id
                    }

                }
            },
            permisions(){

                if(state.user.rol.Name == 'Comercial'){

                    if(state.user.email == 'jarodriguez@pamesa.com'){

                        return true

                    } else {

                        return false

                    }

                } else if(state.user.rol.Name == 'Gerente'){

                    if(state.user.email == 'imilian@pamesa.com'){

                        return true

                    } else if(state.user.email == 'msebastia@tauceramica.com'){

                        return true

                    } else if( state.user.email == 'jpiquer@geotiles.com'){

                        return true

                    } else {

                        return false

                    }

                } else {

                    return false

                }

            },
            rol(){
                return state.user.rol.Name
            },
            rolpermisions(){

                if(this.rol == 'Propiedad'){

                    if(this.user.email == 'admin@mail.zeus.vision'){

                        return true

                    } else if(this.user.email == 'jmllaneza@pamesa.com'){

                        return true

                    } else if(this.user.email == 'apalacios@pamesa.com'){

                        return true

                    } else {

                        return false

                    }

                } else if(this.rol == 'Gerente'){

                    if(this.user.email == 'msebastia@tauceramica.com'){

                        return true

                    } else if(this.user.email == 'imilian@pamesa.com'){

                        return true

                    } else if(this.user.email == 'jpiquer@geotiles.com'){

                        return true

                    } else {

                        return false

                    }

                } else {

                    return false

                }

            }
            
        },
        methods:{
            getReduceName(name){
                return name.slice(0,3)+'.'
            },
            toTops(){
                this.$router.push( { name: 'comercialtops' } )
            },
            toKpis(){
                this.$router.push({name:'kpis'})
            },
            toRepresentants(){
                this.$router.push({name:'representants'})
            },
            gotocomercialfilters(){
                this.$router.push({name:'comercialfilters'})
            },
            openFacturation(){
                this.facturation = !this.facturation
                this.metroscuadrados = false
            },
            openMeters(){
                this.metroscuadrados = !this.metroscuadrados
                this.facturation = false
            },
            openPreciomedio(){
                this.preciomedio = !this.preciomedio
                this.margen = false
            },
            openMargen(){
                this.margen = !this.margen
                this.preciomedio = false
            },
            toClients(){
                this.$router.push({name: 'clients'})
            },
            toVisits(){
                this.$router.push({name:'visits'})
            }
        }
    }
</script>